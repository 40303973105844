<template>
  <div>

    <!-- #region::Searcher and action button -->
    <BasicListFilters
      title="Vendedores"
      new-item-button-label="Nuevo vendedor"
      searcher-placeholder="Buscar vendedores"
      :show-create-model="canManageSellerUsers"
      @on:filter="handleFilter"
      @on:click="isSidebarOpen = true"
    />
    <!-- #endregion::Searcher and action button -->

    <!-- #region::Skeleton -->
    <template v-if="isLoadingSellers">
      <b-skeleton
        class="mt-1"
        animation="wave"
        width="100%"
        height="430px"
      />
    <!-- #endregion::Skeleton -->
    </template>

    <template v-else>
      <b-card
        class="mt-1 border border-dark shadow-none"
        no-body
      >
        <b-card-text>

          <!-- #region::Sellers list table -->
          <b-table
            v-if="availableSellers"
            :items="sellers"
            :fields="columns"
            responsive
            class="my-0"
          >

            <!-- #region:Virtual column for index -->
            <template #cell(No)="data">
              {{ indexFrom + data.index }}
            </template>
            <!-- #endregion:Virtual column for index -->

            <template #cell(Name)="{ item }">
              <router-link
                :to="{ name: 'detail-seller', params: { id: item.IdUser } }"
              ><span class="text-nowrap">{{ item.Name }}</span>
              </router-link>
            </template>

            <template #cell(quotes)="{ item }">
              {{ item.quotes | placeholder('Sin especificar') }}
            </template>

            <template #cell(orders)="{ item }">
              {{ item.orders | placeholder('Sin especificar') }}
            </template>

            <template #cell(quotesRequest)="{ item }">
              {{ item.quotesRequest | placeholder('Sin especificar') }}
            </template>

            <template #cell(created_at)="{ item }">
              {{ item.created_at | moment("DD/MM/YYYY") }}
            </template>

            <!-- #region::A virtual column for actions -->
            <template #cell(actions)="data">

              <div class="d-flex justify-content-between">

                <!-- #region::Delete seller button -->
                <b-button
                  v-b-tooltip.hover.top="'Eliminar'"
                  variant="flat-secondary"
                  class="btn-icon rounded-circle"
                  :disabled="!canManageSellerUsers"
                  @click="onDeleteSeller(data)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
                <!-- #endregion::Delete seller button -->

                <!-- #region::Edit seller button -->
                <b-button
                  v-b-tooltip.hover.top="'Editar'"
                  variant="flat-secondary"
                  class="btn-icon rounded-circle"
                  :disabled="!canManageSellerUsers"
                  @click="onUpdateSeller(data)"
                >
                  <feather-icon icon="Edit3Icon" />
                </b-button>
              <!-- #endregion::Edit seller button -->

              </div>

            </template>
            <!-- #endregion::A virtual column for actions -->

          </b-table>
          <!-- #endregion::Sellers list table -->

          <!-- #region::Alert when no circuits are available -->
          <b-alert
            v-if="!availableSellers && !isLoadingSellers"
            variant="warning"
            class="my-0"
            show
          >
            <div class="alert-body">
              <span>No se encontraron vendedores.</span>
            </div>
          </b-alert>
          <!-- #endregion::Alert when no circuits are available -->

        </b-card-text>
      </b-card>
    </template>

    <b-sidebar
      id="sidebar-right"
      v-model="isSidebarOpen"
      bg-variant="white"
      width="30em"
      :no-close-on-backdrop="true"
      right
      backdrop
      no-header
      shadow
    >
      <SaveSellerForm
        ref="saveSellerForm"
        @on:close="isSidebarOpen = false"
        @on:reload="loadSellersList"
      />
    </b-sidebar>

    <!-- #region begin::Pagination & items per list -->
    <BasicPaginator
      v-if="availableSellers"
      ref="basic-paginator"
      class="mt-2"
      :callback="handleChangePagination"
      :total-rows="totalSellers"
    />
    <!-- #endregion end::Pagination & items per list -->
  </div>
</template>

<script>
// #region Imports
import { mapGetters, mapActions } from 'vuex'
import {
  VBTooltip, BSidebar, VBToggle, BSkeleton, BCard, BCardText, BTable, BButton, BAlert,
} from 'bootstrap-vue'

import BasicPaginator from '@/components/tables/BasicPaginator.vue'
import BasicListFilters from '@/components/tables/BasicListFilters.vue'
import SaveSellerForm from '@/modules/users/sellers/views/SaveSellerForm.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { adminLevel, ADMIN_USER_LEVELS } from '@/helpers/UserLevel'

import getError from '@/helpers/ErrorsHandler'
// #endregion

export default {
  components: {
    BCard,
    BTable,
    BAlert,
    BButton,
    BSidebar,
    BSkeleton,
    BCardText,
    SaveSellerForm,
    BasicPaginator,
    BasicListFilters,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      lastPage: 0,
      indexFrom: 0,
      totalSellers: 0,
      columns: [
        // A virtual column that doesn't exist in items
        {
          key: 'No',
          label: 'No.',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'Name',
          label: 'Nombre',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'Company',
          label: 'Empresa',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'email',
          label: 'Correo electrónico',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light text-nowrap',
        },
        {
          key: 'Phone',
          label: 'Teléfono',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'quotes',
          label: 'No. Cotizaciones',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light text-nowrap',
        },
        {
          key: 'orders',
          label: 'No. Órdenes',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light text-nowrap',
        },
        {
          key: 'quotesRequest',
          label: 'No. Solicitudes',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light text-nowrap',
        },
        {
          key: 'created_at',
          label: 'Creado',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
        {
          key: 'actions',
          label: 'Acciones',
          thStyle: 'text-transform: none;',
          thClass: 'bg-light',
        },
      ],
      sellers: [],
      isSidebarOpen: false,
      isLoadingSellers: false,

      // Role validation
      adminLevel,
      ADMIN_USER_LEVELS,
    }
  },
  computed: {
    ...mapGetters({
      getFilteredPerPage: 'filters/getFilteredPerPage',
      getFilteredSearchTerm: 'filters/getFilteredSearchTerm',
      getFilteredCurrentPage: 'filters/getFilteredCurrentPage',
    }),
    /**
     * Indica la cantidad de elementos por página (paginado global)
     */
    filteredPerPage: {
      get() { return this.getFilteredPerPage },
      set(value) { this.setFilteredPerPage(value) },
    },
    /**
     * Término de búsqueda filtrado (filtro en store)
     */
    filteredSearchTerm: {
      get() { return this.getFilteredSearchTerm },
      set(value) { this.setFilteredSearchTerm(value) },
    },
    /**
     * Indica la página actual (paginado global)
     */
    filteredCurrentPage: {
      get() { return this.getFilteredCurrentPage },
      set(value) { this.setFilteredCurrentPage(value) },
    },
    availableSellers() {
      return this.totalSellers > 0
    },

    /**
     * Indica si el usuario puede gestionar usuarios vendedores
     */
    canManageSellerUsers() {
      return this.adminLevel() === this.ADMIN_USER_LEVELS.ADMINISTRADOR
    },
  },

  /**
  * Hook que se ejecuta cuando se carga el componente
  *
  * @summary Carga la lista de usuarios vendedor
  */
  async created() {
    await this.loadSellersList()
    this.verifyContenList()
  },
  methods: {
    ...mapActions({
      loadSellers: 'sellers/loadSellers',
      deleteSeller: 'sellers/deleteSeller',
      setFilteredPerPage: 'filters/setFilteredPerPage',
      setFilteredSearchTerm: 'filters/setFilteredSearchTerm',
      setFilteredCurrentPage: 'filters/setFilteredCurrentPage',
    }),

    /**
    * Evento de filtrado
    *
    * @summary Atiende el evento filtrado del componente de filtros
    * @param {Object} filter - Objeto con el tipo de filtro y el valor
    */
    async handleFilter(filter) {
      this.filteredSearchTerm = filter.value
      this.filteredCurrentPage = 1

      // eslint-disable-next-line no-unused-expressions
      this.$refs['basic-paginator']?.resetCurrentPage()
      await this.loadSellersList()
      this.verifyContenList()
    },

    /**
    * Evento de paginación
    *
    * @summary Evento del componente de paginación. Establece la página actual y la cantidad
    * de registros por página
    * @param {number} currentPage - Página actual
    * @param {number} perPage - Elementos a mostrar por página
    */
    async handleChangePagination(currentPage, perPage) {
      this.filteredCurrentPage = currentPage
      this.filteredPerPage = perPage
      this.loadSellersList()
    },

    /**
    * Verificación de contenido
    *
    * @summary Verifica que la página actual tenga registros, en caso de no tenerlos
    * tomará la última página disponible y cargará los registros
    */
    async verifyContenList() {
      if (this.sellers.length === 0) {
        this.filteredCurrentPage = this.lastPage

        // Verifica si hay registros disponibles en el servidor
        if (this.availableSellers) {
          this.$refs['basic-paginator'].resetCurrentPage(this.lastPage)
          await this.loadSellersList()
        }
      }
    },

    /**
    * Carga de lista de usuarios vendedor
    *
    * @summary Atiende la carga de la lista de usuarios vendedor y establece
    * los valores de la paginación
    * @exception Muestra un mensaje de error en caso de que la petición
    * al servidor falle
    */
    async loadSellersList() {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()
        this.isLoadingSellers = true

        const response = await this.loadSellers(this.loadParams())
        this.sellers = response.data.data.users.data
        this.totalSellers = response.data.data.users.total
        this.indexFrom = response.data.data.users.from
        this.lastPage = response.data.data.users.last_page

        this.isLoadingSellers = false
        this.$swal.close()
      } catch (error) {
        this.$swal.close()
        this.showToast('Error de validación', getError(error), 'danger')
      }
    },

    /**
    * Eliminación de usuario vendedor
    *
    * @summary Confirma la eliminación de un usuario vendedor y recarga la lista
    * @param {Ojbject} item - Objeto con la información del usuario vendedor
    * @exception Muestra un mensaje de error en caso de que la petición al servidor falle
    */
    async onDeleteSeller({ item }) {
      const result = await this.$swal({
        title: '¿Deseas continuar?',
        text: '¡La información y registros del vendedor serán eliminados!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        this.$swal({
          title: 'Espere por favor',
          allowOutsideClick: false,
        })

        this.$swal.showLoading()

        try {
          await this.deleteSeller(item.IdUser)
          await this.loadSellersList()
          await this.verifyContenList()

          this.$swal.fire('Eliminado', '¡El vendedor ha sido eliminado correctamente!', 'success')
        } catch (error) {
          this.$swal.close()
          this.showToast('Error de validación', error.response.data.message, 'danger')
        }
      }
    },

    /**
    * Actualización de usuario vendedor
    *
    * @summary Muestra el formulario de creación/actualización de usuario vendedor y carga la información
    * @param {Object} item - Objeto con la información del usuario vendedor
    */
    onUpdateSeller({ item }) {
      console.log(item)
      this.isSidebarOpen = true
      this.$refs.saveSellerForm.loadExistingSeller(item)
    },

    /**
    * Parámetros de carga
    *
    * @summary Devuelve los parámetros de carga de la lista
    */
    loadParams() {
      return {
        perPage: this.filteredPerPage,
        resource: 'sellers',
        searchTerm: this.filteredSearchTerm,
        currentPage: this.filteredCurrentPage,
      }
    },

    /**
    * Muestra un mensaje de tipo toast
    *
    * @param {string} title - Título del mensaje
    * @param {string} text - Contenido del mensaje
    * @param {string} variant - Tipo del mensaje (warning, success, danger)
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>

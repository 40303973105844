var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BasicListFilters',{attrs:{"title":"Vendedores","new-item-button-label":"Nuevo vendedor","searcher-placeholder":"Buscar vendedores","show-create-model":_vm.canManageSellerUsers},on:{"on:filter":_vm.handleFilter,"on:click":function($event){_vm.isSidebarOpen = true}}}),(_vm.isLoadingSellers)?[_c('b-skeleton',{staticClass:"mt-1",attrs:{"animation":"wave","width":"100%","height":"430px"}})]:[_c('b-card',{staticClass:"mt-1 border border-dark shadow-none",attrs:{"no-body":""}},[_c('b-card-text',[(_vm.availableSellers)?_c('b-table',{staticClass:"my-0",attrs:{"items":_vm.sellers,"fields":_vm.columns,"responsive":""},scopedSlots:_vm._u([{key:"cell(No)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.indexFrom + data.index)+" ")]}},{key:"cell(Name)",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'detail-seller', params: { id: item.IdUser } }}},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(item.Name))])])]}},{key:"cell(quotes)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("placeholder")(item.quotes,'Sin especificar'))+" ")]}},{key:"cell(orders)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("placeholder")(item.orders,'Sin especificar'))+" ")]}},{key:"cell(quotesRequest)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("placeholder")(item.quotesRequest,'Sin especificar'))+" ")]}},{key:"cell(created_at)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created_at,"DD/MM/YYYY"))+" ")]}},{key:"cell(actions)",fn:function(data){return [_c('div',{staticClass:"d-flex justify-content-between"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Eliminar'),expression:"'Eliminar'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon rounded-circle",attrs:{"variant":"flat-secondary","disabled":!_vm.canManageSellerUsers},on:{"click":function($event){return _vm.onDeleteSeller(data)}}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}})],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Editar'),expression:"'Editar'",modifiers:{"hover":true,"top":true}}],staticClass:"btn-icon rounded-circle",attrs:{"variant":"flat-secondary","disabled":!_vm.canManageSellerUsers},on:{"click":function($event){return _vm.onUpdateSeller(data)}}},[_c('feather-icon',{attrs:{"icon":"Edit3Icon"}})],1)],1)]}}],null,false,4224914733)}):_vm._e(),(!_vm.availableSellers && !_vm.isLoadingSellers)?_c('b-alert',{staticClass:"my-0",attrs:{"variant":"warning","show":""}},[_c('div',{staticClass:"alert-body"},[_c('span',[_vm._v("No se encontraron vendedores.")])])]):_vm._e()],1)],1)],_c('b-sidebar',{attrs:{"id":"sidebar-right","bg-variant":"white","width":"30em","no-close-on-backdrop":true,"right":"","backdrop":"","no-header":"","shadow":""},model:{value:(_vm.isSidebarOpen),callback:function ($$v) {_vm.isSidebarOpen=$$v},expression:"isSidebarOpen"}},[_c('SaveSellerForm',{ref:"saveSellerForm",on:{"on:close":function($event){_vm.isSidebarOpen = false},"on:reload":_vm.loadSellersList}})],1),(_vm.availableSellers)?_c('BasicPaginator',{ref:"basic-paginator",staticClass:"mt-2",attrs:{"callback":_vm.handleChangePagination,"total-rows":_vm.totalSellers}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }